import gql from 'graphql-tag';
import { FRAGMENT_USER_MAIN_INFO } from '../';

export const LOGIN = gql`
    query login($login: String!, $password: String!) {
        login(login: $login, password: $password) {
            ...UserMainInfo
            token
        }
    }
    ${FRAGMENT_USER_MAIN_INFO}
`;

export const SSO_AUTHORIZATION =  gql`
    query checkSsoAuthorization {
        checkSsoAuthorization
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  Subscription,
  withApollo,
} from 'react-apollo';
import NotificationSystem from 'react-notification-system';
import moment from 'moment';

import { VACATION_STATUSES, REQUEST_STATUSES } from '../../environment';
import { COMMON_SUBSCRIPTION } from '../../gql';

import './SubscriptionWithNotifications.css';
import { updateEventsCache } from '../updateQueriesCache/eventQueriesCache';
import { updateEventCommentsCache } from '../updateQueriesCache/eventCommentQueriesCache';
import { updateVacationsCache } from '../updateQueriesCache/vacationQueriesCache';
import { updateRequstsCache } from '../updateQueriesCache/requestQueriesCache';
import { updateMenuCache } from '../updateQueriesCache/menuQueriesCache';

class SubscriptionWithNotifications extends React.PureComponent {
  static propTypes = {
    history: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    client: PropTypes.shape().isRequired,
    isRole: PropTypes.func.isRequired,
  };

  notificationSystem = React.createRef();

  componentDidMount() {
    if (moment().utcOffset() !== moment().utcOffset('+0300').utcOffset()) {
      this.timeZoneWarningNotification();
    }
  }

  commentNotification = (comment) => {
    const notification = {
      title: `Новый комментарий в событии: "${comment.event.name}"`,
      message: `<strong>${comment.owner.firstName} ${comment.owner.lastName}</strong><br />${comment.text}`,
      /**
       * actions will be allow if remove/check last data in <Subscription>
       */
      action: {
        label: 'К событию',
        callback: () => {
          this.notificationSystem.current.removeNotification(this.notificationSystem.current);
          this.props.history.push(`/event/${comment.eventId}`);
        },
      },
    }
    this.addNotification(notification);
  }

  eventNotification = (event) => {
    const notification = {
      title: `Новое событие: "${event.name}"`,
      message: `Организатор: <strong>${event.owner.firstName} ${event.owner.lastName}</strong><br />Дата: ${moment(event.eventStartAt).format('LLL')}`,
    }
    this.addNotification(notification);
  }

  timeZoneWarningNotification = () => {
    const notification = {
      title: 'Обратите внимание',
      message: 'На вашем компьютере выставлен неверный часовой пояс. Приложение может работать некорректно!',
      level: 'warning',
    }
    this.addNotification(notification);
  }

  vacationNotification = (vacation) => {
    const notification = {};
    if (vacation.status === VACATION_STATUSES.waiting) {
      if (!this.props.isRole(['admin', 'manager', 'hrmanager', 'pm'])) return null;
      notification.title = 'Новый отпуск';
      notification.message = `<strong>${vacation.owner.firstName} ${vacation.owner.lastName}</strong>`;
    } else {
      if (this.props.isRole(['admin', 'manager', 'hrmanager', 'pm'])) return null;
      notification.title = 'Отпуск';
      notification.level = vacation.status === VACATION_STATUSES.done ? 'success' : 'warning';
      notification.message = `<strong>${vacation.status === VACATION_STATUSES.done ? 'Одобрен' : 'Отклонен'}</strong>`;
    }
    this.addNotification(notification);
  }

  requestNotification = (request) => {
    const notification = {};
    if (request.status === REQUEST_STATUSES.waiting) {
      if (!this.props.isRole(['admin', 'manager', 'hrmanager', 'pm'])) return null;
      notification.title = `Новая заявка: "${request.name}"`;
      notification.message = `<strong>${request.owner.firstName} ${request.owner.lastName}</strong>`;
    } else {
      if (this.props.isRole(['admin', 'manager', 'hrmanager', 'pm'])) return null;
      notification.title = `Заявка: "${request.name}"`;
      notification.level = request.status === REQUEST_STATUSES.done ? 'success' : 'warning';
      notification.message = `<strong>${request.status === REQUEST_STATUSES.done ? 'Выполнена' : 'Отклонена'}</strong>`;
    }
    this.addNotification(notification);
  }

  addNotification = (notification) => {
    const defaultNotification = {
      level: 'success',
      position: 'br',
      // autoDismiss: 500,
    }
    this.notificationSystem.current.addNotification({ ...defaultNotification, ...notification });
  }


  render() {
    const { user, client } = this.props;
    return (
      <Subscription
        subscription={COMMON_SUBSCRIPTION}
      >
        {({ data }) => {
          const {
            commonSubscription: {
              eventComment,
              event,
              vacation,
              request,
              logout,
              actionBy,
              menu,
            } = {},
          } = data || {};

          if (eventComment && eventComment.id) {
            updateEventCommentsCache(client, eventComment);
            if (eventComment.ownerId !== user.id) {
              this.commentNotification(eventComment);
            }
          }
          if (event && event.id) {
            updateEventsCache(client, event);
            if (event.ownerId !== user.id) {
              this.eventNotification(event);
            }
          }
          if (vacation && vacation.id) {
            updateVacationsCache(client, vacation);
            this.vacationNotification(vacation);
          }
          if (request && request.id) {
            updateRequstsCache(client, request);
            if (actionBy.id !== user.id) {
              this.requestNotification(request);
            }
          }
          if (logout && logout.id === user.id) {
            localStorage.setItem('token', '');
            localStorage.setItem('user', '');
            client.resetStore();
            return <Redirect to="/login" />;
          }
          if (menu) {
            updateMenuCache(client, menu);
          }

          if (data) data.commonSubscription = {
            eventComment: {},
            event: {},
            vacation: {},
            request: {},
          }

          return <NotificationSystem ref={this.notificationSystem} allowHTML />;
        }}
      </Subscription>
    );
  }
}

export default withApollo(SubscriptionWithNotifications);

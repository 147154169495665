import gql from 'graphql-tag';

export const FRAGMENT_VACATION_INFO = gql`
    fragment VacationInfo on Vacation {
        id
        ownerId
        startAt
        endAt
        comment
        status
        leave
        owner {
            id
            firstName
            lastName
            office
            companyName
        }
    }
`;

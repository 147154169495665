import React, { Component } from 'react';
import { Query, Mutation, graphql, compose } from 'react-apollo';

import { MenuOrderCard, OrderTime, Checkbox, SideColumnWithMobileBottomLine, Loader, DishRatingModal } from '../../components';

import './Menu.css';

import { MENU_GROUP_BY_CATEGORY, USER_ORDERS, AVAILABLE_DISH_RATING, SKIP_DISH_RATING } from '../../gql';
import { titleCase, countBoxes } from '../../helpers';

class Menu extends Component {
  static getDerivedStateFromProps(nextProps, state) {
    const { loading } = nextProps.data;
    const userOrders = (nextProps.data.userOrders || []).length ? nextProps.data.userOrders[0] : {};
    if (!loading && !state.orderReceived) {
      const additionalCost = userOrders.withYou ? countBoxes(userOrders.dishes.map(({ dish }) => dish) || []).length * 20 : 0
      return {
        userOrders: userOrders.dishes || [],
        orderId: userOrders.id || '',
        withYou: userOrders.withYou || false,
        orderReceived: true,
        additionalCost,
      };
    }
    return null;
  }

  componentDidMount(){
    this.props.availableDishRating.refetch();
  }

  componentWillUnmount(){
    this.props.availableDishRating.refetch();
  }

  constructor(props) {
    super(props);
    this.state = {
      userOrders: [],
      orderId: '',
      isTimeExpire: false,
      withYou: false,
      orderReceived: false,
      additionalCost: 0,
      closedRatingDishes: false,
    };
  }

  hideRatingDishes = (skipDishRating)=>{
    this.setState({closedRatingDishes:true},()=>{
      skipDishRating()
    });
  }

  setOrder = (dish) => {
    const count = 1;
    const userOrders = this.state.userOrders.filter(({ dish: d }) => d.id !== dish.id);
    if (userOrders.length === this.state.userOrders.length) {
      userOrders.push({ dish, count });
    }
    const additionalCost = this.state.withYou ? countBoxes(userOrders.map(({ dish: d }) => d)).length * 20 : 0
    this.setState({ userOrders, additionalCost });
  }

  removeOrder = () => {
    this.setState({ userOrders: [], orderId: '', withYou: false, additionalCost: 0 });
  }

  timeExpire = (time) => {
    this.setState({
      isTimeExpire: !time,
    });
  }

  setWithYou = () => {
    const additionalCost = this.state.withYou ? 0 : countBoxes(this.state.userOrders.map(({ dish }) => dish)).length * 20
    this.setState({ withYou: !this.state.withYou, additionalCost });
  }
  changeCount = (dish, increment) => {
    const count = this.findDishInOrder(dish).count + increment
    this.findDishInOrder(dish).count = count > 0 ? Math.min(count, 5) : 1
    this.setState({ userOrders: this.state.userOrders });
  }

  findDishInOrder = dish => this.state.userOrders.find(({ dish: d }) => d.id === dish.id)

  render() {
    const sideMenuList = [
      {
        name: 'createOrder',
        title: 'Сделать заказ',
      }];
    return (
      <Query
        query={MENU_GROUP_BY_CATEGORY}
      >
        {({ loading, error, data, client }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const list = data.menuGroupByCategory.filter(e => e.menu.length) || [];

          return (
            <div className="Menu container-with-fixed-header">
              <div className="html ui attached grid custom-reverse">
                <OrderTime timeExpire={this.timeExpire} client={client} isOrderExist={!!this.state.userOrders.length} />
                <div className="ui column twelve wide">
                  {list.map((category) => (
                    <div key={category.id} className={`menu-category ui table padded selectable unstackable`}>
                      <div className="menu-category_header">
                            <h2>{titleCase(category.rusName)}</h2>
                      </div>
                      <div className="menu-category_body">
                        {category.menu.map(dish => (
                          <div
                            key={dish.id}
                            className={`menu-category_body_row ${!!this.findDishInOrder(dish) && 'positive'} ${this.state.isTimeExpire && 'custom-disabled'}`}
                          >
                            <div
                              className="menu-category_body_checkbox-wrapper"
                              onClick={() => this.setOrder(dish)}
                            >
                              <Checkbox checked={!!this.findDishInOrder(dish)} />
                            </div>
                            <div className="celled flexible">
                              <h4 className="ui image header" onClick={() => this.setOrder(dish)}>
                                <div className="content">
                                  {dish.name}
                                  {dish.description && <div className="sub header">{dish.description}</div>}
                                </div>
                              </h4>
                              {!!this.findDishInOrder(dish) &&
                                <div className="menu-category_body_counter" >
                                  <i className="angle left icon" onClick={() => this.changeCount(dish, -1)} />
                                  {this.findDishInOrder(dish).count || 1}
                                  <i className="angle right icon" onClick={() => this.changeCount(dish, 1)} />
                                </div>
                              }
                            </div>
                            <div className="ten-percent">{dish.cost} руб</div>
                            <div className="dish-rating">
                              <div >{dish.rating?dish.rating.toFixed(1):'—'}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                </div>
                <div className="ui column four wide">
                  <MenuOrderCard
                    isTimeExpire={this.state.isTimeExpire}
                    userOrders={this.state.userOrders}
                    orderId={this.state.orderId}
                    removeOrder={this.removeOrder}
                    setWithYou={this.setWithYou}
                    withYou={this.state.withYou}
                    additionalCost={this.state.additionalCost}
                  />
                </div>
                <SideColumnWithMobileBottomLine
                  menuList={sideMenuList}
                >
                  <MenuOrderCard
                    isTimeExpire={this.state.isTimeExpire}
                    userOrders={this.state.userOrders}
                    orderId={this.state.orderId}
                    removeOrder={this.removeOrder}
                    setWithYou={this.setWithYou}
                    withYou={this.state.withYou}
                    additionalCost={this.state.additionalCost}
                  />
                </SideColumnWithMobileBottomLine>
              </div>
                {this.props.availableDishRating.availableDishRating&&this.props.availableDishRating.availableDishRating.length?
                    <Mutation mutation={SKIP_DISH_RATING}>
                        {(skipDishRating)=>
                          <DishRatingModal dishes={this.props.availableDishRating.availableDishRating}
                                           isOpen={this.props.availableDishRating.availableDishRating
                                           && this.props.availableDishRating.availableDishRating.length
                                           && !this.state.closedRatingDishes}
                                           hide={()=>this.hideRatingDishes(skipDishRating)}
                                           query1={AVAILABLE_DISH_RATING}
                                           query2={MENU_GROUP_BY_CATEGORY}
                          >

                          </DishRatingModal>

                        }

                    </Mutation>
                    :''}
            </div>
          );
        }}

      </Query>
    );
  }
}

export default compose(
    graphql(AVAILABLE_DISH_RATING, {
        name:'availableDishRating',
        options: {},
    }),
    graphql(USER_ORDERS, {
        name:'data',
        options: { fetchPolicy: 'no-cache' },
    }),
)(Menu);

import gql from 'graphql-tag';
import {
  FRAGMENT_USER_FULL_STATISTIC,
  FRAGMENT_CURRENT_ORDERS,
  FRAGMENT_CURRENT_VACATIONS,
  FRAGMENT_CURRENT_SICKS,
  FRAGMENT_USER_SALARY_STATISTIC,
  FRAGMENT_ADMIN_SALARY_STATISTIC,
  FRAGMENT_USER_FULL_INFO,
} from '../';
import { FRAGMENT_USER_FULL_INFO_WITH_STAFF } from '../user/fragment';

export const FULL_STATISTIC = gql`
    query FullStatistic($id: ID!, $year: Int, $month: Int) {
        fullStatistic(id: $id, year: $year, month: $month) {
            ...UserFullStatistic
        }
    }
    ${FRAGMENT_USER_FULL_STATISTIC}
`;

export const SALARY_STATISTIC = gql`
    query SalaryStatistic($id: ID!, $month: Int, $year: Int) {
        salaryStatistic(id: $id, month: $month, year: $year) {
            ...UserSalaryStatistic
        }
    }
    ${FRAGMENT_USER_SALARY_STATISTIC}
`;

export const ADMIN_SALARY_STATISTIC = gql`
    query AdminSalaryStatistic($id: ID!, $month: Int, $year: Int) {
        salaryStatistic(id: $id, month: $month, year: $year) {
            ...AdminSalaryStatistic
        }
    }
    ${FRAGMENT_ADMIN_SALARY_STATISTIC}
`;

export const ADMIN_BULK_SALARY_STATISTIC = gql`
    query AdminBulkSalaryStatistic($id: ID, $month: Int, $year: Int, $isIncludeHolidays: Boolean) {
        bulkSalaryStatistic(id: $id, month: $month, year: $year, isIncludeHolidays: $isIncludeHolidays) {
            user {
              ...UserFullInfoWithStaffNumber
            }
            statistic {
              sicks {
                month
              }
              vacations {
                month
              }
              daysOfMonth {
                monthWorkdays
                monthHolidays
              }
            }
        }
    }
    ${FRAGMENT_USER_FULL_INFO_WITH_STAFF}
`;

export const CURRENT_ORDERS = gql`
    query CurrentOrders($month: Int, $year: Int) {
        currentOrders(month: $month, year: $year) {
            ...CurrentOrders
        }
    }
    ${FRAGMENT_CURRENT_ORDERS}
`;

export const CURRENT_VACATIONS = gql`
    query CurrentVacations($month: Int, $year: Int) {
        currentVacations(month: $month, year: $year) {
            ...CurrentVacations
        }
    }
    ${FRAGMENT_CURRENT_VACATIONS}
`;

export const CURRENT_SICKS = gql`
    query CurrentSicks($month: Int, $year: Int) {
        currentSicks(month: $month, year: $year) {
            ...CurrentSicks
        }
    }
    ${FRAGMENT_CURRENT_SICKS}
`;

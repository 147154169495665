import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import stylePropType from 'react-style-proptype';

import { isTouchDevice } from '../../helpers';
import './HomeUserItem.css';

const isDevice = isTouchDevice();
class HomeUserItem extends React.PureComponent {
  render() {
    const { user, device, style, imgStyle, actionIcon, noLink } = this.props;
    return (
      <div
        className={`HomeUserItem ui card ${user.role && user.role.name === 'trainee' && 'trainee'} ${!device && 'no-custom-shadow'}`}
        style={style}
      >
        {noLink ?
          <a
            href='javasript:void(0)'
            className={`image ${!device && 'thumbnail'} ${user.lastName.length > 10 && 'small-pseudo-before'} ${user.firstName.length > 9 && 'small-pseudo-after'}`}
            data-firstname={user.firstName}
            data-lastname={user.lastName}
          >
            <img src={user.avatar || '/assets/images/image.png'} alt={`${user.firstName} ${user.lastName}`} style={imgStyle} />
          </a>
        :
          <Link
            to={`/profile/${user.id}`}
            className={`image ${!device && 'thumbnail'} ${user.lastName.length > 10 && 'small-pseudo-before'} ${user.firstName.length > 9 && 'small-pseudo-after'}`}
            data-firstname={user.firstName}
            data-lastname={user.lastName}
          >
            <img src={user.avatar || '/assets/images/image.png'} alt={`${user.firstName} ${user.lastName}`} style={imgStyle} />
          </Link>
        }
        {device &&
          <div className="extra content">
            {user.firstName} {user.lastName}
          </div>
        }
        {actionIcon}
      </div>
    )
  }
};

HomeUserItem.propTypes = {
  user: PropTypes.shape().isRequired,
  device: PropTypes.bool,
  noLink: PropTypes.bool,
  style: stylePropType,
  imgStyle: stylePropType,
  actionIcon: PropTypes.node,
};

HomeUserItem.defaultProps = {
  style: {},
  imgStyle: {},
  device: isDevice,
  actionIcon: null,
  noLink: false,
};

export default HomeUserItem;

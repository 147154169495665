import React, { Component } from 'react';

import './AdminScheduleVacationsAndSicks.css';

import { AdminScheduleVacations, AdminTableVacations, AdminContext } from '../../components';

class AdminScheduleVacationsAndSicks extends Component {
  state = {
    tabIndex: 0,
  }

  render() {
    const { tabIndex } = this.state;
    return (
      <div className="AdminScheduleVacationsAndSicks">
        <AdminContext.Consumer>
          {({ isAdmin, isBookkeeper }) => (
            <div className="ui styled accordion">
              <div
                className={`title ${tabIndex === 0 && 'active'}`}
                onClick={() => this.setState({ tabIndex: 0 })}
              >
                <i className="dropdown icon"></i>
                График отпусков и больничных
              </div>
              {tabIndex === 0 && (
                <div className={`content ${tabIndex === 0 && 'active'}`}>
                  <AdminScheduleVacations />
                </div>
              )}
              <div className={`${!(isAdmin || isBookkeeper) && 'hidden'}`}>
                <div
                  className={`title ${tabIndex === 1 && 'active'}`}
                  onClick={() => this.setState({ tabIndex: 1 })}
                >
                  <i className="dropdown icon"></i>
                  Таблица отпусков и больничных
                </div>
                {tabIndex === 1 && (
                  <div className={`content ${tabIndex === 1 && 'active'}`}>
                    <AdminTableVacations />
                  </div>
                )}
              </div>
            </div>
          )}
        </AdminContext.Consumer>

      </div>
    );
  }
}

export default AdminScheduleVacationsAndSicks;

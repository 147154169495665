import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Order } from '../../pages';
import { AdminMenu, AdminOrderReports, AdminPhones, AdminCalendar, AdminScheduleVacationsAndSicks, AdminSettingsPage } from '../pages';

const Routes = (props) => (
  <Switch>
    <Route exact path="/admin/order" component={Order} />
    <Route exact path="/admin/menu" component={AdminMenu} />
    <Route exact path="/admin/orderReports" component={AdminOrderReports} />
    <Route exact path="/admin/scheduleVacation" component={AdminScheduleVacationsAndSicks} />
    <Route exact path="/admin/settings" component={AdminSettingsPage} />
    <Route exact path="/admin/phones" component={AdminPhones} />
    <Route exact path="/admin/calendar" component={AdminCalendar} />
    <Route render={() => <Redirect to={`${props.isRole('admin') ? '/admin/order' : '/admin/scheduleVacation'}`} />} />
  </Switch>
);
Routes.propTypes = {
  isRole: PropTypes.func.isRequired,
}
export default Routes;

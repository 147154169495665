import gql from 'graphql-tag';

export const FRAGMENT_BEST_USER_INFO = gql`
    fragment BestUserInfo on BestUser {
        id
        userId
        blockSlug
        user {
            id
            firstName
            lastName
            avatar
        }
    }
`;

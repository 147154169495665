import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import moment from 'moment';
import 'moment/locale/ru';

import { YearsSelector, MonthsSelector, Loader } from '../..';
import { FULL_STATISTIC } from '../../../gql';
import { caseOfMonth } from '../../../helpers';
import './UserStatistic.css';

const months = moment.localeData('ru').months();
const currentMonth = moment().month();
const currentYear = moment().year();

class UserStatistic extends Component {
  static propTypes = {
    profileOwner: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      selectedMonth: currentMonth,
      selectedYear: currentYear,
    };
  }

  countStrokeArray = ({ radius, data, size }) => {
    const circumference = 2 * Math.PI * radius;
    let corner = 0;
    switch (this.state.tabIndex) {
      case 1:
        if (size === 'small') {
          const cost = moment().year() === this.state.selectedYear && moment().month() === this.state.selectedMonth ? data.orders.currentWeek.totalCost : 0;
          corner = cost / data.orders.year.totalCost * circumference;
        } else if (size === 'big') {
          corner = data.orders.month.totalCost / data.orders.year.totalCost * circumference;
        } else {
          corner = circumference;
        }
        break;
      case 2:
        if (size === 'small') {
          corner = data.requests.month.all / data.requests.total.all * circumference;
        } else if (size === 'big') {
          corner = data.requests.year.all / data.requests.total.all * circumference;
        } else {
          corner = circumference;
        }
        break;
      case 3:
        if (size === 'small') {
          corner = data.sicks.month / data.sicks.total * circumference;
        } else if (size === 'big') {
          corner = data.sicks.year / data.sicks.total * circumference;
        } else {
          corner = circumference;
        }
        break;
      case 4:
        if (size === 'small') {
          corner = data.vacations.month / data.vacations.total * circumference;
        } else if (size === 'big') {
          corner = data.vacations.year / data.vacations.total * circumference;
        } else {
          corner = circumference;
        }
        break;
      default: break;
    }
    return `${corner} ${circumference}`;
  }

  renderStatisticsInfo = (statistic) => {
    let data = null;
    switch (this.state.tabIndex) {
      case 1:
        data = (
          <div>
            <h3>Потрачено на обеды</h3>
            <p><i className="color-example forest-green" />
              Ваши траты за весь {this.state.selectedYear} год: <b>{Intl.NumberFormat().format(statistic.orders.year.totalCost - statistic.orders.year.payByCompany)}р</b>
              {statistic.orders.year.additionalCost > 0 && <b className="red"> +{statistic.orders.year.additionalCost}p</b>}
              . Полная сумма: <b>{statistic.orders.year.totalCost}р</b>
            </p>
            <p><i className="color-example medium-blue" />
              Ваши траты в {caseOfMonth(months[this.state.selectedMonth])} месяце: <b>{Intl.NumberFormat().format(statistic.orders.month.totalCost - statistic.orders.month.payByCompany)}р</b>
              {statistic.orders.month.additionalCost > 0 && <b className="red"> +{statistic.orders.month.additionalCost}p</b>}
              . Полная сумма: <b>{statistic.orders.month.totalCost}р</b>
            </p>
            {moment().year() === this.state.selectedYear && moment().month() === this.state.selectedMonth &&
            <p><i className="color-example dark-orange"/>
              Ваши траты на этой неделе: <b>{Intl.NumberFormat().format(statistic.orders.currentWeek.totalCost - statistic.orders.currentWeek.payByCompany)}р</b>
              {statistic.orders.currentWeek.additionalCost > 0 &&
              <b className="red"> +{statistic.orders.currentWeek.additionalCost}p</b>}
              . Полная сумма: <b>{statistic.orders.currentWeek.totalCost}р</b>
            </p>
            }
          </div>
        )
        break;
      case 2:
        data = (
          <div>
            <h3>Количество заявок</h3>
            <p><i className="color-example forest-green" /> За всё время: <b>{statistic.requests.total.all}</b>. Одобренных/Отклонённых: <b>{statistic.requests.total.done} / {statistic.requests.total.rejected}</b></p>
            <p><i className="color-example medium-blue" /> В {this.state.selectedYear} году: <b>{statistic.requests.year.all}</b>. Одобренных/Отклонённых: <b>{statistic.requests.year.done} / {statistic.requests.year.rejected}</b></p>
            <p><i className="color-example dark-orange" /> В {caseOfMonth(months[this.state.selectedMonth])} месяце: <b>{statistic.requests.month.all}</b>. Одобренных/Отклонённых: <b>{statistic.requests.month.done} / {statistic.requests.month.rejected}</b></p>
          </div>
        )
        break;
      case 3:
        data = (
          <div>
            <h3>Количество дней на больничном</h3>
            <p><i className="color-example forest-green" /> За всё время: <b>{statistic.sicks.total}</b></p>
            <p><i className="color-example medium-blue" /> В {this.state.selectedYear} году: <b>{statistic.sicks.year}</b></p>
            <p><i className="color-example dark-orange" /> В {caseOfMonth(months[this.state.selectedMonth])} месяце: <b>{statistic.sicks.month}</b></p>
          </div>
        )
        break;
      case 4:
        data = (
          <div>
            <h3>Количество дней в отпуске</h3>
            <p><i className="color-example forest-green" /> За всё время: <b>{statistic.vacations.total}</b></p>
            <p><i className="color-example medium-blue" /> В {this.state.selectedYear} году: <b>{statistic.vacations.year}</b></p>
            <p><i className="color-example dark-orange" /> В {caseOfMonth(months[this.state.selectedMonth])} месяце: <b>{statistic.vacations.month}</b></p>
          </div>
        )
        break;
      default: break;
    }
    return data;
  }
  onChangeSelectedYear = (year) => {
    if (year === this.state.selectedYear) return;
    this.setState({ selectedYear: year })
  }
  onChangeSelectedMonth = (month) => {
    if (month === this.state.selectedMonth) return;
    this.setState({ selectedMonth: month })
  }

  render() {
    return (
      <Query
        query={FULL_STATISTIC}
        variables={{ id: this.props.profileOwner.id, year: this.state.selectedYear, month: this.state.selectedMonth }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const statistic = data.fullStatistic || {};
          return (
            <div className="UserStatistic" >
              <div className="ui grid">
                <div className="sixteen wide row date-selector-block">
                  <div>
                    <label><b>За год</b></label>
                    <YearsSelector year={this.state.selectedYear} changeYear={this.onChangeSelectedYear} className="small-dropdown" />
                  </div>
                  <div>
                    <label><b>За месяц</b></label>
                    <MonthsSelector month={this.state.selectedMonth} changeMonth={this.onChangeSelectedMonth} className="small-dropdown" />
                  </div>

                </div>
                <div className="four wide column">
                  <div className="ui vertical fluid tabular menu">
                    <a className={`item ${this.state.tabIndex === 1 && 'active'}`} onClick={() => this.setState({ tabIndex: 1 })}>
                      Обеды
                    </a>
                    <a className={`item ${this.state.tabIndex === 2 && 'active'}`} onClick={() => this.setState({ tabIndex: 2 })}>
                      Заявки
                    </a>
                    <a className={`item ${this.state.tabIndex === 3 && 'active'}`} onClick={() => this.setState({ tabIndex: 3 })}>
                      Больничные
                    </a>
                    <a className={`item ${this.state.tabIndex === 4 && 'active'}`} onClick={() => this.setState({ tabIndex: 4 })}>
                      Отпуска
                    </a>
                  </div>
                </div>
                <div className="twelve wide stretched column">

                  <div className="ui segment">
                    <div className="inline-flex">
                      <svg className="pie-chart-statistic">
                        <circle r="20" cx="50" cy="50" className="full-pie" style={{ strokeDasharray: this.countStrokeArray({ radius: 20, data: statistic, size: 'full' }) }} />
                        <circle r="22" cx="50" cy="50" className="big-pie" style={{ strokeDasharray: this.countStrokeArray({ radius: 22, data: statistic, size: 'big' }) }} />
                        <circle r="25" cx="50" cy="50" className="small-pie" style={{ strokeDasharray: this.countStrokeArray({ radius: 25, data: statistic, size: 'small' }) }} />
                      </svg>
                    </div>
                    <div className="statistic-info">
                      {this.renderStatisticsInfo(statistic)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default UserStatistic;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';

import {
  SAVE_ORDER,
  REMOVE_ORDER,
  EDIT_ORDER,
  TODAY_ORDERS,
  USER_ORDERS,
  CURRENT_ORDERS,
} from '../../gql';
import './MenuOrderCard.css';
import { Checkbox, UserContext, ErrorHandler } from '../index';
import { countBoxes, sortList } from '../../helpers';

const totalCost = userOrders => (userOrders.length ? userOrders.map(({ dish, count }) => dish.cost * (count || 1)).reduce((prev, curr) => (prev + curr)) : []);

const LIMIT = 200;

class MenuOrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOrderSaving: false,
      isOrderRemoving: false,
    };
  }

  queriesCacheUpdate = (order, store) => {
    const queries = [
      { query: TODAY_ORDERS, variables: { day: moment().date() } },
      { query: USER_ORDERS, variables: { month: null, year: null } },
    ];

    queries.forEach((query) => {
      try {
        const cache = store.readQuery(query);
        // add to 'todayOrder' list
        if (cache.todayOrders) {
          //remove
          if (typeof order === 'string') {
            cache.todayOrders = cache.todayOrders.filter(e => e.id !== order)
          } else {
            // add or replace in cache
            const i = cache.todayOrders.findIndex(e => e.id === order.id);
            if (i > -1) cache.todayOrders[i] = order;
            else cache.todayOrders.unshift(order);
          }
          cache.todayOrders = sortList({ list: cache.todayOrders, sortBy: 'ownerId', type: 'number' })
        }
        // add to 'userOrder' list
        if (cache.userOrders) {
          //remove
          if (typeof order === 'string') {
            cache.userOrders = [];
          } else {
            cache.userOrders = [order]
          }
        }
        store.writeQuery({ ...query, data: cache });
      } catch (error) {}
    });
  }

  onSaveOrder = (saveOrder) => {
    this.setState({ isOrderSaving: true });
    const { props } = this;
    saveOrder({
      variables: props.orderId ?
        {
          order: {
            id: props.orderId,
            dishes: props.userOrders.map(({ dish }) => dish.id),
            countedDishes: props.userOrders.map(({ dish, count }) => ({ id: dish.id, count })),
            totalCost: totalCost(props.userOrders),
            withYou: props.withYou,
            additionalCost: props.additionalCost,
          },
        } : {
          order: {
            dishes: props.userOrders.map(({ dish }) => dish.id),
            countedDishes: props.userOrders.map(({ dish, count }) => ({ id: dish.id, count })),
            totalCost: totalCost(props.userOrders),
            withYou: props.withYou,
            additionalCost: props.additionalCost,
          },
        },
      update: (store, { data: { saveOrder: res, editOrder } }) => {
        this.queriesCacheUpdate(res || editOrder, store);
      },
      refetchQueries: [
        { query: CURRENT_ORDERS },
      ],
    });
  }

  onRemoveOrder = (removeOrder) => {
    this.props.removeOrder();
    if (!this.props.orderId) {
      this.setState({ orderErrorMessage: '' });
      return;
    }
    this.setState({ isOrderRemoving: true, orderErrorMessage: '' });
    removeOrder({
      variables: {
        order: this.props.orderId,
      },
      update: (store, { data: { removeOrder: res } }) => {
        this.queriesCacheUpdate(res, store);
      },
      refetchQueries: [
        { query: CURRENT_ORDERS },
      ],
    });
  }

  render() {
    // const dishesInBoxes = this.props.withYou ? countBoxes(this.props.userOrders.map(({ dish }) => dish)) : [];
    const isOrderExist = !!this.props.userOrders.length;
    const countedCost = totalCost(this.props.userOrders);
    const disabled = this.props.isTimeExpire || !isOrderExist || this.state.isOrderSaving || this.state.isOrderRemoving;
    return (
      <UserContext.Consumer>
        {({ isRole }) => {
          const isTraineeCostLimit = isRole('trainee') && (countedCost > LIMIT)
          return (
            <div
              className={`MenuOrderCard ui card fixed ${(this.props.isTimeExpire || !isOrderExist) && 'disabled'}`}
            >
              <div className="content">
                <div className="header">Ваш заказ</div>
                <div className="meta">
                  {!isOrderExist && !this.props.isTimeExpire && <span className="date">Закажи что-нибудь вкусненькое</span>}
                  {!isOrderExist && this.props.isTimeExpire && <span className="date">Вы ничего не заказали</span>}
                </div>
                {isOrderExist &&
                <div className="description">
                  {this.props.userOrders.map(({ dish, count }) => {
                    let orderNameClass = 'width-without-cost'
                    if (count > 1 && !this.props.withYou) orderNameClass = 'width-without-count-cost'
                    if (count <= 1 && this.props.withYou) orderNameClass = 'width-without-additional-cost'
                    if (count > 1 && this.props.withYou) orderNameClass = 'width-without-all-cost'

                    return (
                      <div key={dish.id}>
                        <div className={`text-ellipsis ${orderNameClass}`}>
                          {dish.name}
                        </div>
                        <span className="right">
                          {count > 1 &&
                          <span className="green">
                            {count}x
                          </span>
                          }
                          <b>{dish.cost}p</b>
                          {/*{dishesInBoxes.length > 0 && dishesInBoxes.includes(dish.id) && <b className="red"> +20p</b>}*/}
                        </span>
                      </div>
                    );
                  })}
                </div>
                }
              </div>
              {isOrderExist &&
              <React.Fragment>
                <div className="extra content">
                  <div className="description for-small-screen">
                    {this.props.additionalCost > 0 &&
                      <React.Fragment>
                        <div className="for-small-screen">
                          <span>Сумма заказа:</span>
                          <span className="right">
                            {countedCost}p
                          </span>
                        </div>
                        <div className="for-small-screen">
                          <span>Контейнеры, {this.props.additionalCost / 20}шт:</span>
                          <span className="right">
                            {this.props.additionalCost}p
                          </span>
                        </div>
                      </React.Fragment>
                    }
                    <span><b>Общая сумма:</b></span>
                    <span className="right">
                      <span className="common-cost">
                        <b>{countedCost + this.props.additionalCost}p</b>
                      </span>
                      <span className="">
                        <b>{Intl.NumberFormat().format(countedCost * .5 + this.props.additionalCost)}p</b>
                      </span>
                    </span>
                  </div>
                </div>
                <div className="extra content">
                  <div
                    onClick={() => this.props.setWithYou()}
                    className={`description flex-block ${disabled && 'custom-disabled'}`}
                  >
                    <Checkbox checked={this.props.withYou} />
                    <label>Завернуть с собой </label>
                  </div>
                  {this.props.withYou &&
                    <div className="with-you-warning red">ВАЖНО: заказывая на вынос к вашей сумме будет добавлено 20р за каждый контейнер для еды</div>
                  }
                  {isTraineeCostLimit &&
                    <div className="with-you-warning red">ВАЖНО: суммма вашего заказа не должна превышать {LIMIT}р</div>
                  }
                </div>
              </React.Fragment>
              }
              {!this.props.isTimeExpire &&
                <div className="ui buttons bottom attached">
                  <Mutation
                    mutation={this.props.orderId ? EDIT_ORDER : SAVE_ORDER}
                    onError={(error) => {
                      this.setState({ isOrderSaving: false, orderErrorMessage: error });
                      console.error('onError ', this.props.orderId ? 'EDIT_ORDER' : 'SAVE_ORDER', error);
                    }}
                    onCompleted={(data) => {
                      console.info('onCompleted ', this.props.orderId ? 'EDIT_ORDER' : 'SAVE_ORDER', data);
                    }}
                  >
                    {(saveOrder, { data }) => {
                      if (data) return <Redirect to={{
                        pathname: '/order',
                        state: { goToTable: true },
                      }} />;
                      return (
                        <div
                          className={`ui button green ${this.state.isOrderSaving && 'loading'} ${(disabled || isTraineeCostLimit) && 'disabled'}`}
                          onClick={() => this.onSaveOrder(saveOrder)}
                        >
                          <i className="check icon" />
                          Заказать
                        </div>
                      );
                    }}
                  </Mutation>
                  <Mutation
                    mutation={REMOVE_ORDER}
                    onError={(error) => {
                      this.setState({ isOrderRemoving: false, orderErrorMessage: error });
                      console.error('onError REMOVE_ORDER', error);
                    }}
                    onCompleted={(data) => {
                      this.setState({ isOrderRemoving: false });
                      console.info('onCompleted REMOVE_ORDER', data);
                    }}
                  >
                    {removeOrder => (
                      <div
                        data-modal="shouldClose"
                        className={`ui icon button negative ${disabled && 'disabled'}`}
                        onClick={() => this.onRemoveOrder(removeOrder)}
                      >
                        <i className="times icon" />
                      </div>
                    )}
                  </Mutation>
                </div>
              }
              {this.state.orderErrorMessage &&
                <ErrorHandler error={this.state.orderErrorMessage} />
              }

            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

MenuOrderCard.propTypes = {
  userOrders: PropTypes.arrayOf(PropTypes.shape),
  orderId: PropTypes.string,
  setWithYou: PropTypes.func,
  removeOrder: PropTypes.func,
  withYou: PropTypes.bool,
  isTimeExpire: PropTypes.bool,
};
MenuOrderCard.defaultProps = {
  userOrders: [],
  orderId: '',
  setWithYou: () => {},
  removeOrder: () => {},
  withYou: false,
  isTimeExpire: false,
};

export default MenuOrderCard;

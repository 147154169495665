import gql from 'graphql-tag';
import { FRAGMENT_USER_FULL_INFO, FRAGMENT_USER_MAIN_INFO } from '../';

export const ME = gql`
    query me {
        me {
            ...UserFullInfo
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;
export const USER = gql`
    query UserQuery($id: ID!) {
        user(id: $id) {
            ...UserFullInfo
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;
export const ALL_USERS = gql`
    query allUsers($filter: Filter) {
        allUsers(filter: $filter) @connection(key: "allUsers", filter: ["filter"]) {
            ...UserFullInfo
            status
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;

export const PAGINATION_USERS = gql`
    query paginationUsers($filter: Filter, $pageInfo: PageInfo) {
        paginationUsers(filter: $filter, pageInfo: $pageInfo) @connection(key: "paginationUsers", filter: ["filter", "pageInfo"]) {
            results {
                ...UserFullInfo
                status
            }
            cursors {
                hasNext
                hasPrevious
                before
                after
            }
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;

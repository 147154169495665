import React from 'react';

import './Navbar.css';

const navbarElementId = 'space-navbar';

class Navbar extends React.PureComponent {

  componentDidMount() {
    document.dispatchEvent(
      new CustomEvent('navbar-place-ready', { detail: navbarElementId }),
    );
  }

  render() {
    return (
      <div className='Navbar'>
         <div className='space-navbar' id={navbarElementId}></div>
      </div>
    );
  }
}

export default Navbar;
import React, { Component } from 'react';
import moment from 'moment';

import {
  VacationList,
  CreateVacationForm,
  UserContext,
  UsersSelector,
  PageSidePanel,
  CurrentStatistic,
  SideColumnWithMobileBottomLine,
} from '../../components';
import { AdminVacationsList } from '../../admin/components';
import { CURRENT_VACATIONS } from '../../gql';
import { VACATION_STATUSES } from '../../environment';

import './Vacation.css';

class Vacation extends Component {
  state = {
    showContent: '',
    userFilter: {},
  };

  getListSidePanel = (isAdmin) => {
    const generalWord = isAdmin ? 'Все' : 'Мои';
    return [
      {
        name: 'create',
        title: 'Новый отпуск',
        userPosition: 1,
        adminPosition: 5,
      },
      {
        name: 'all',
        title: `${generalWord} отпуска`,
        userPosition: 2,
        adminPosition: 2,
      },
      {
        name: 'open',
        title: 'В ожидании',
        userPosition: 3,
        adminPosition: 1,
      },
      {
        name: 'done',
        title: 'Подтвержденные',
        userPosition: 4,
        adminPosition: 3,
      },
      {
        name: 'rejected',
        title: 'Отклонненые',
        userPosition: 5,
        adminPosition: 4,
      },
    ];
  };

  changeContent = showContent => this.setState({ showContent });
  changeUser = userFilter => this.setState({ userFilter });

  renderContentByUser = (user, isAdmin) => {
    const { showContent, userFilter } = this.state;
    const filter = {
      ownerId: userFilter.id,
    };
    switch (showContent) {
      case 'create':
        filter.endDate = moment().startOf('day');
        filter.status = VACATION_STATUSES.done;
        filter.ownerId = user.id;
        return <CreateVacationForm changeContent={this.changeContent} variables={{ filter }} />;
      case 'all': break;
      case 'open':
        filter.status = VACATION_STATUSES.waiting;
        break;
      case 'done':
        filter.status = VACATION_STATUSES.done;
        break;
      case 'rejected':
        filter.status = VACATION_STATUSES.rejected;
        break;
      default:
        if (isAdmin) {
          filter.status = VACATION_STATUSES.waiting;
        } else {
          filter.status = VACATION_STATUSES.done;
          filter.endDate = moment().startOf('day');
          filter.ownerId = user.id;
        }
        return isAdmin ?
          <AdminVacationsList variables={{ filter }} />
          :
          <CreateVacationForm changeContent={this.changeContent} variables={{ filter }} />;
    }
    return isAdmin ?
      <AdminVacationsList variables={{ filter }} />
      :
      <VacationList variables={{ filter }} />;
  };

  renderHintMessageBlock = (isAdmin) => {
    let hintText = `Если вы ошиблись с датами отпуска пожалуйста
      попросите своего руководителя отклонить текущий отпуск и создайте новый.`;
    if (isAdmin) {
      hintText = `Если сотрудник ошибся с датами отпуска пожалуйста
        отклоните текущий отпуск и попросите его создать новый.`;
    }
    return (
      <div className="ui card info message hide-on-mobile">
        <div className="content">
          <h4>Памятка</h4>
          <div className="description">
            {hintText}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="Vacation container-with-fixed-header">
        <UserContext.Consumer>
          {({ user, isRole }) => (
            <div className="html ui attached grid custom-reverse">
              <div className="ui column twelve wide">
                {((!this.state.showContent && isRole(['admin', 'manager', 'hrmanager', 'pm'])) || (this.state.showContent && this.state.showContent !== 'create')) &&
                  <div className="flex-block search-block">
                    {isRole(['admin', 'manager', 'hrmanager', 'pm']) &&
                      <UsersSelector user={this.state.userFilter} changeUser={this.changeUser} />
                    }
                    <div>
                      <div><i className="color-example yellow" /> Запланированные отпуска.</div>
                      <div><i className="color-example green" /> Текущие отпуска.</div>
                      <div><i className="color-example blue" /> Прошедшие отпуска.</div>
                    </div>
                  </div>
                }
                <div className={`
                  ${((!this.state.showContent && isRole(['admin', 'manager', 'hrmanager', 'pm'])) || (this.state.showContent && this.state.showContent !== 'create')) && 'padding-for-search-block'}
                  ${isRole(['admin', 'manager', 'hrmanager', 'pm']) && 'for-admin'}
                `}
                >
                  {this.renderContentByUser(user, isRole(['admin', 'manager', 'hrmanager', 'pm']))}
                </div>
              </div>
              <div className="ui column four wide">
                <div className="ui cards fixed mobile-side-menu">
                  <PageSidePanel
                    changeContent={this.changeContent}
                    showContent={this.state.showContent}
                    isAdmin={isRole(['admin', 'manager', 'hrmanager', 'pm'])}
                    menuList={this.getListSidePanel(isRole(['admin', 'manager', 'hrmanager', 'pm']))}
                  />
                  <CurrentStatistic type="currentVacations" query={CURRENT_VACATIONS} />
                  {this.renderHintMessageBlock(isRole(['admin', 'manager', 'hrmanager', 'pm']))}
                </div>
              </div>
              <SideColumnWithMobileBottomLine
                menuList={this.getListSidePanel(isRole(['admin', 'manager', 'hrmanager', 'pm']))}
                changeContent={this.changeContent}
                activeItem={this.state.showContent}
                isAdmin={isRole(['admin', 'manager', 'hrmanager', 'pm'])}
              />
            </div>
          )}
        </UserContext.Consumer>

      </div>
    );
  }
}

export default Vacation;

import React from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import './ProfileRolesCard.css';
import { CHANGE_ROLE, ROLES } from '../../../gql';
import { Loader, UserContext } from '../../';

const ProfileRolesCard = props => (
  <Query
    query={ROLES}
  >
    {({ loading, error, data }) => {
      if (loading) return <Loader />;
      if (error)throw new Error(error);

      return (
        <UserContext.Consumer>
          {({ user }) => (
            <div className={`ProfileRolesCard ${(props.profileOwner.status === 'blocked' || user.role.position > props.profileOwner.role.position) && 'custom-disabled'}`}>
              <div className="ui segment attached secondary">
                <h4 className="header">Роль Сотрудника</h4>
              </div>
              <Mutation mutation={CHANGE_ROLE}>
                {(changeRole, { loading }) => (
                  <div className={`ui segment bottom attached ${loading && 'loading'}`}>
                    {data.roles.length > 0 && data.roles.map(role => (
                      <div className="field ui" key={role.id}>
                        <div
                          className={`ui radio checkbox ${user.role.position > role.id && user.role.position <= props.profileOwner.role.position && 'custom-disabled'}`}
                          onClick={() => changeRole({
                              variables: {
                                user: {
                                  roleId: role.id,
                                  id: props.profileOwner.id,
                                  role: {
                                    position: props.profileOwner.role.position,
                                  },
                                },
                              },
                            })
                            }
                        >
                          <input type="radio" name="role" checked={props.profileOwner.roleId === role.id} onChange={()=>{}} />
                          <label>{role.rusName}</label>
                        </div>
                      </div>
                      ))}

                  </div>
                  )}
              </Mutation>
            </div>
          )}
        </UserContext.Consumer>
      );
    }}
  </Query>
);

ProfileRolesCard.propTypes = {
  profileOwner: PropTypes.shape(),
};

ProfileRolesCard.defaultProps = {
  profileOwner: {},
};

export default ProfileRolesCard;

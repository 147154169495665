import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    Home,
    Profile,
    Menu,
    Order,
    OrderHistory,
    Request,
    Handbook,
    Sick,
    Vacation,
    Events,
    Event,
    NotFound,
    Emergency,
    NoAccess,
} from '../pages/index';
import AdminContainer from '../admin/AdminContainer';

const Routes = ({ user }) => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/profile/:id" component={Profile} />
    <Route exact path="/menu" component={user.status === 'inactive' ? NoAccess : Menu} />
    <Route exact path="/order" component={user.status === 'inactive' ? NoAccess : Order} />
    <Route exact path="/orderHistory" component={OrderHistory} />
    <Route exact path="/(handbook-dunice|handbook-academy|handbook-italyan)" component={Handbook} />
    <Route exact path="/handbook-emergency" component={Emergency} />
    <Route exact path="/request" component={Request} />
    <Route exact path="/sick" component={Sick} />
    <Route exact path="/vacation" component={Vacation} />
    <Route exact path="/event" component={Events} />
    <Route exact path="/event/:id" component={Event} />
    <Route path="/admin" component={AdminContainer} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;

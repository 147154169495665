import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import { VACATION_STATUSES } from '../../../environment';
import { declOfNum, sortList } from '../../../helpers';
import { PAGINATION_VACATIONS } from '../../../gql';
import { Loader } from '../..';
import './VacationsList.css';

class VacationsList extends Component {
  static propTypes = {
    variables: PropTypes.shape(),
  }

  static defaultProps = {
    variables: {},
  }

  stylesByStatus = (status) => {
    if (status === VACATION_STATUSES.done) return 'green';
    if (status === VACATION_STATUSES.rejected) return 'red';
    return 'orange';
  }
  stylesByDate = (vacation) => {
    const today = moment().startOf('day').hours(12);
    if (moment(vacation.endAt).diff(moment(), 'days') < 0) return 'blue';
    if (moment(vacation.startAt).diff(today, 'days') > 0) return 'yellow';
    return 'green';
  }
  datesSortable = (list) => {
    const today = moment().startOf('day').hours(12);
    const blue = sortList({
      list: list.filter(vacation => moment(vacation.endAt).diff(moment(), 'days') < 0),
      sortBy: 'startAt',
      type: 'date',
      sortDirection: 'DESC',
    });
    const yellow = sortList({
      list: list.filter(vacation => moment(vacation.startAt).diff(today, 'days') > 0),
      sortBy: 'startAt',
      type: 'date',
      sortDirection: 'DESC',
    });
    const green = sortList({
      list: list.filter(vacation => !(moment(vacation.startAt).diff(today, 'days') > 0 || moment(vacation.endAt).diff(moment(), 'days') < 0)),
      sortBy: 'startAt',
      type: 'date',
      sortDirection: 'DESC',
    });

    return [...yellow, ...green, ...blue];
  }

  renderHeaderDurations = (vacation) => {
    const days = moment(vacation.endAt).startOf('day').diff(moment(vacation.startAt).startOf('day'), 'days') + 1
    return (
      <React.Fragment>
        <div className="ui segment vacation-card_sub-header_durations">
          <span>Начало: <b>{moment(new Date(vacation.startAt)).format('DD.MM.YY')}</b></span>
          <span className="durations_end-time">Конец: <b>{moment(new Date(vacation.endAt)).format('DD.MM.YY')}</b></span>
          <span className={`vacation-card_leave vacation-card_leave--${vacation.leave}`}>
            {vacation.leave === 'unpaid' ? 'Не оплачиваемый' : 'Оплачиваемый'}
          </span>
        </div>
        <div className="ui segment vacation-card_mobile-sub-header_durations">
          С:&nbsp;<b>{moment(new Date(vacation.startAt)).format('DD.MM.YY')}</b>
          <span className="durations_end-time">До: <b>{moment(new Date(vacation.endAt)).format('DD.MM.YY')}</b></span>
          <span className={`vacation-card_leave vacation-card_leave--${vacation.leave}`}>
            {vacation.leave === 'unpaid' ? 'Не оплачиваемый' : 'Оплачиваемый'}
          </span>
        </div>
        <div className={`ui segment vacation-card_sub-header_days ${this.stylesByDate(vacation)}`}>
          <b>
            {days} {declOfNum(days, ['день', 'дня', 'дней'])}
          </b>
        </div>
      </React.Fragment>
    );
  }

  renderHeaderButtons = (vacation) => {
    return (
      <div className="ui pseudo-segment vacation-card_header_buttons">
        <div className={`ui button status only-icon-for-small-width ${this.stylesByStatus(vacation.status)}`}>
          <React.Fragment>
            {vacation.status === VACATION_STATUSES.waiting &&
            <React.Fragment>
              <i className="icon button sync"/>
              <span>в ожидании</span>
            </React.Fragment>
            }
            {vacation.status === VACATION_STATUSES.done &&
            <React.Fragment>
              <i className="icon button checkmark"/>
              <span>подтверждено</span>
            </React.Fragment>
            }
            {vacation.status === VACATION_STATUSES.rejected &&
            <React.Fragment>
              <i className="icon button close"/>
              <span>отклонено</span>
            </React.Fragment>
            }
          </React.Fragment>
        </div>
      </div>
    );
  }

  showVacationModal = () => {}
  renderVacationCard = (vacation = {}) => (
    <div
      className="ui segments vacation-card"
      key={vacation.id}
      onClick={() => this.showVacationModal(vacation)}
    >
      <div className="ui horizontal segments vacation-card_header">
        <div className="ui pseudo-segment vacation-card_header_number">
          <b>{vacation.id}</b>
        </div>
        <div className="ui segment vacation-card_header_owner">
          <b>
            {vacation.owner && vacation.owner.firstName} {vacation.owner && vacation.owner.lastName} ({ vacation.owner && vacation.owner.companyName })
          </b>
        </div>
        {this.renderHeaderButtons(vacation)}
      </div>

      {vacation.comment &&
      <div className="ui segment vacation-card_comments">
        {vacation.comment}
      </div>
      }

      <div className="ui horizontal segments vacation-card_sub-header">
        {this.renderHeaderDurations(vacation)}
      </div>
    </div>
  )

  render() {
    const policy = this.props.variables.filter && this.props.variables.filter.ownerId ? 'no-cache' : 'cache-first';
    return (
      <div className="VacationsList">
        <Query
          query={PAGINATION_VACATIONS}
          variables={this.props.variables}
          fetchPolicy={policy}
        >
          {({ loading, error, data, fetchMore }) => {
            if (loading) return <Loader />;
            if (error) throw new Error(error);

            const { paginationVacations: { results: vacations = [], cursors = {} } = {} } = data || {};
            const loadMore = () => fetchMore({
              variables: { ...this.props.variables, pageInfo: { after: cursors.after } },
              updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
                const previousSearch = previousResult.paginationVacations || {};
                const currentSearch = fetchMoreResult.paginationVacations || {};
                const previousNodes = previousSearch.results || [];
                const currentNodes = currentSearch.results || [];
                return {
                  ...previousResult,
                  paginationVacations: {
                    ...previousSearch,
                    results: [...previousNodes, ...currentNodes],
                    cursors: currentSearch.cursors,
                  },
                };
              },
            });

            return (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={cursors.hasNext}
                useWindow
                loader={<div className="loader" key={0}>Загрузка ...</div>}
              >
                {this.datesSortable(vacations).map(vacation => this.renderVacationCard(vacation))}
              </InfiniteScroll>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default VacationsList;

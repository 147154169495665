import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Link, Redirect } from 'react-router-dom';
import InputMask from 'react-input-mask';

import './AdminPhones.css';

import { ALL_USERS } from '../../../gql';
import { Loader } from '../../../components';

class AdminPhones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFilter: '',
      isBlockedInclude: false,
    };
  }
  prepareData = list => list
    .filter(e => (this.state.isBlockedInclude ? true : e.status !== 'blocked'))
    .filter(e => e.firstName.toLowerCase().includes(this.state.userFilter.toLowerCase()) || e.lastName.toLowerCase().includes(this.state.userFilter.toLowerCase()))

  filterList = ({ target: { value } }) => this.setState({ userFilter: value });

  render() {
    return (
      <div className="AdminPhones">
        <Query
          query={ALL_USERS}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) throw new Error(error);

            const users = (data && data.allUsers) || [];

            const list = this.prepareData(users)
            return (
              <div className="html ui grid">
              <div className="column twelve wide">
                <div className="ui icon input fluid">
                  <input type="text" placeholder="Поиск по сотрудникам..." onChange={this.filterList} />
                  <i className="search link icon" />
                </div>
                <div
                  className="ui checkbox"
                  onClick={() => this.setState({ isBlockedInclude: !this.state.isBlockedInclude })}
                >
                  <input type="checkbox" name="order" checked={!this.state.isBlockedInclude} />
                  <label>Исключить заблокированных из списка (помечены цветом <i className="color-example negative" />)</label>
                </div>
              <table className="ui celled table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Сотрудник</th>
                    <th>Телефон</th>
                  </tr>
                </thead>
                <tbody>
                {list.map((user) => (
                  <tr key={user.id} className={`${user.status === 'blocked' && 'negative'}`}>
                    <td>
                      {user.id}
                    </td>
                    <td>
                      <div className="content">
                        <Link
                          to={`/profile/${user.id}`}
                        >
                          {user.firstName} {user.lastName}
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className="content">
                        <a href={`tel:${user.phone.startsWith('+7') ? user.phone : `+7${user.phone}`}`}>
                          <InputMask
                            disabled
                            name="phone"
                            mask="+7 (999) 999-99-99"
                            value={user.phone}
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
              </div>
                <div className="column four wide">
                  <div className="monthCard ui card floated right">
                    <div className="content">
                      <div className="header">Служебные</div>

                      <div className="description">
                          <div>
                            <span>Абсолют</span>
                            <span className="right">312-112</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            );
          }}
        </Query>
      </div>
    );
  }
}

export default AdminPhones;

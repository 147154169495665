import gql from 'graphql-tag';

export const FRAGMENT_ROLE_INFO = gql`
    fragment RoleInfo on Role {
        id
        name
        rusName
        position
    }
`;

import { useState } from 'react'

import HandbookTooltip from '../../handbook-tooltip'

import './index.scss';

export default function InteractiveFloor({SvgBody, offices, isInteractive = true, ...props}) {
    
    const [activeOffice, setActiveOffice] = useState(null);
    const [popupPosition, setPopupPosition] = useState(null);

    const onMouseMove = (e) => {
        setPopupPosition({
            x: e.pageX,
            y: e.clientY,
        })
    }

    return <div className="interactive-floor">

        {isInteractive && activeOffice && <HandbookTooltip
            left={popupPosition?.x}
            top={popupPosition?.y}
        >
            <div className="interactive-floor__tooltip-container">
                <div className="interactive-floor__tooltip-office">
                    {activeOffice.officeNum}
                </div>
                <div className="interactive-floor__tooltip-content">
                    <activeOffice.Content/>
                </div>
            </div>
        </HandbookTooltip>}


        <SvgBody className="interactive-floor__svg" {...props}>
                {offices.map((office) => {
                    const {officeNum, dMask} = office;
                    return <g key={officeNum}>
                        <g 
                            style={{
                                // cursor: 'help',
                                transition: 'opacity 0.3s',
                                opacity: (isInteractive && activeOffice?.officeNum === officeNum) ? 0.5 : 0.000001,
                            }}
                            onMouseEnter={() => isInteractive && setActiveOffice(office)}
                            onMouseMove={onMouseMove}
                            onMouseLeave={() => isInteractive && setActiveOffice(null)}

                        >
                            <path d={dMask} fill="#00A55E" ></path>;
                        </g>                                
                    </g>
                })}
        </SvgBody>

    </div>
}
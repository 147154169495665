import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';

import 'react-datepicker/dist/react-datepicker.css';

import registerServiceWorker, { unregister as  unregisterServiceWorker } from './registerServiceWorker';
import { MainContainer } from './components';
import { Login, Signup, ForgotPassword } from './pages';

import './semantic/dist/semantic.css';
import './index.css';

const httpLink = process.env.NODE_ENV === 'development'
  ? `http://${window.location.hostname}:4000/graphql`
  : 'https://tgn.staff.space.dunice.net/graphql';
const mainLink = createUploadLink({
  uri: httpLink,
  headers: {
    'keep-alive': 'true',
  },
  credentials: 'include',
});

let wsLink;
const getConnectionParams = () => ({ authToken: localStorage.getItem('token') || '' });
const wsOptions = {
  reconnect: true,
  timeout: 2000,
  connectionParams: getConnectionParams(),

  connectionCallback: (err) => {
    if (err) {
      wsLink.subscriptionClient.close(false, false);
      wsLink.subscriptionClient.connectionParams = getConnectionParams;
      wsLink.subscriptionClient.connect();
    }
  },
};
wsLink = process.env.NODE_ENV === 'development'
  ? new WebSocketLink({ uri: `ws://${window.location.hostname}:4000/subscription`, options: wsOptions })
  : new WebSocketLink({ uri: 'wss://staff.dunice.net/subscription', options: wsOptions });

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  mainLink,
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});



const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route component={MainContainer} />
      </Switch>
    </Router>
  </ApolloProvider>
);

render(<App />, document.getElementById('root'));
unregisterServiceWorker();
// registerServiceWorker();


import './index.scss'

export default function HandbookBanner({ kind }) {

    return (
        <div className="handbook-banner">

            <div className="handbook-banner__section_left">
                <h1 className="handbook-banner__title">Добро пожаловать в IT-компанию IBS Dunice!</h1>
                <div className="handbook-banner__text">
                    Наша миссия – способствовать бизнес успеху наших клиентов.
                    <br /><br />
                    Мы подбираем разработчиков для наших клиентов, обеспечиваем понятный процесс работы и предоставляем результат в конкретные сроки. Все это позволяет нашим клиентам сконцентрироваться на их бизнес задачах.
                    <br /><br />
                    Главным в работе мы считаем добросовестность, профессионализм и клиентоориентированность.
                </div>
            </div>

            <div className={`handbook-banner__section_image handbook-banner__section_image_${kind}`} />

        </div>
    )
}

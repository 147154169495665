

import _ from 'lodash'
import { useState, useEffect } from 'react'
import InteractiveFloor from './interactive-floor'


import './index.scss'


export default function HandbookOffices({ floors }) {
    const [selectedFloor, setSelectedFloor] = useState(_.minBy(floors, 'level'));

    useEffect(() => {
        setSelectedFloor(_.minBy(floors, 'level'));
    }, [floors]);

    return (
        <div className="handbook-offices">
            <h1 className="handbook__title">
                План этажей
            </h1>

            <div className="handbook-offices__wrapper-wide">
                <div className="handbook-offices__selected-floor">
                    <div className="handbook-offices__selected-floor-title">
                        {selectedFloor.level} этаж
                    </div>
                    <div className="handbook-offices__selected-floor-content-wrapper">
                        {selectedFloor && <InteractiveFloor 
                            className="handbook-offices__selected-floor-content" 
                            {...selectedFloor.data} 
                            isInteractive={true} 
                        />}
                    </div>
                </div>
                {floors.length > 1 && <div className="handbook-offices__selector-section">
                    <div className="handbook-offices__selector-section-title">
                        Выберите этаж
                    </div>
                    <div className="handbook-offices__floor-list">
                        {floors.map(floor => {
                            const { level, data } = floor;
                            return (
                                <div
                                    key={level} 
                                    className={`handbook-offices__floor-item ${selectedFloor.level === level ? 'handbook-offices__floor-item_selected' : ''}`}
                                    onClick={() => setSelectedFloor(floor)}
                                >
                                    <div className="handbook-offices__floor-item-title">
                                        {level} этаж
                                    </div>
                                    <div className="handbook-offices__floor-item-content">
                                        <InteractiveFloor {...data} isInteractive={false} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>}
            </div>



            <div className="handbook-offices__wrapper-small">
                <div className="handbook-offices__floor-list">
                    {floors.map(floor => {
                        const { level, data } = floor;
                        return (
                            <div
                                key={level} 
                                className="handbook-offices__floor-item"
                            >
                                <div className="handbook-offices__floor-item-title">
                                    {level} этаж
                                </div>
                                <div className="handbook-offices__floor-item-content">
                                    <InteractiveFloor {...data} isInteractive={true} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    )
}
import React, { memo } from 'react';

const BurgerMenuIcon = ({ color = 'white', size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
  >
    <path d="M1 3v26h30V3Zm2 24V5h8v22Zm26 0H13V5h16Z" />
    <path d="M5 15h4v2H5zM5 11h4v2H5zM5 7h4v2H5z" />
  </svg>
);

export default memo(BurgerMenuIcon);

import gql from 'graphql-tag';

const USER_FIELDS = `
    id
    login
    firstName
    lastName
    phone
    avatar
    office
    companyName
    priority
    createdAt
    updatedAt
    blockedAt
`;

export const FRAGMENT_USER_MAIN_INFO = gql`
    fragment UserMainInfo on User {
        ${USER_FIELDS}
    }
`;

export const FRAGMENT_USER_MAIN_INFO_WITH_STAFF = gql`
    fragment UserMainInfoWithStaff on UserWithStaffNumber {
        ${USER_FIELDS}
    }
`;
export const FRAGMENT_USER_FULL_INFO = gql`
    fragment UserFullInfo on User {
        ...UserMainInfo
        status
        roleId
        role {
            id
            name
            rusName
            position
        }
    }
    ${FRAGMENT_USER_MAIN_INFO}
`;
export const FRAGMENT_USER_FULL_INFO_WITH_STAFF = gql`
    fragment UserFullInfoWithStaffNumber on UserWithStaffNumber {
        ...UserMainInfoWithStaff
        status
        roleId
        staffNumber
        role {
            id
            name
            rusName
            position
        }
    }
    
    ${FRAGMENT_USER_MAIN_INFO_WITH_STAFF}
`;

import React from 'react';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import { Header, Routes, UserContext, SubscriptionWithNotifications, Loader, ErrorBoundary } from './index';
import { ME } from '../gql';

import Navbar from './Navbar/Navbar';

class MainContainer extends React.PureComponent {
  render() {
    return (
      <Query query={ME}>
        {({
          client, loading, error, data,
        }) => {
          if (loading) return <Loader />;
          if (error) return <Redirect to="/login" />;

          const user = data.me;
          const isRole = roles => user.role && roles.includes(user.role.name);

          return (
            <ErrorBoundary>
              <UserContext.Provider value={{ user, client, isRole }}>
                <Header location={this.props.location} />
                <ErrorBoundary>
                  <Routes user={user} />
                </ErrorBoundary>
                <SubscriptionWithNotifications history={this.props.history} user={user} isRole={isRole} />
                <Navbar />
              </UserContext.Provider>
            </ErrorBoundary>
          );
        }}
      </Query>
    );
  }
}
export default MainContainer;

import { useRef, useEffect, useLayoutEffect, useState } from 'react'
import _ from 'lodash'


import './index.scss'

export default function HandbookTooltip({left, top, ...props}) {
    const root = useRef();
    const [position, setPosition] = useState({left, top});
    const [isHidden, setIsHidden] = useState(true);
    useLayoutEffect(() => {
        const rect = root?.current.getBoundingClientRect();
        if (rect) {
            const { width } = rect;
            const leftGap = 0 + width / 2;
            const leftInt = left || 0;
            let newLeft = Math.max(leftInt, leftGap);;
            if (leftInt > window.innerWidth - leftGap) {
                newLeft = window.innerWidth - leftGap
            }
            setPosition({
                left: newLeft, 
                top,
            });
            setIsHidden(false);
        }
    }, [left, top]);

    useEffect(() => {
        const onScroll = _.once(() => {
            if (!isHidden) {
                setIsHidden(true);
            }
        });
        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        }
    }, [isHidden]);


    return (<>
        <div ref={root} className="handbook-tooltip" style={{
            left: position.left,
            top: position.top,
            visibility: isHidden ? 'hidden' : 'visible',
        }} {...props}>
            {props.children}
            <div 
                className="handbook-tooltip__dialog-piece"
                style={{ 
                    transform: `translateY(20px) translateX(calc(-50% - ${position.left - left}px))`,
                }}
            />
        </div>
    </>)
}